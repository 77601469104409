.floating-toggle-button { 
  cursor: pointer;
  z-index: 10;
  position: fixed;
  top: 20px;
  right: 20px;
}

.floating-toggle-button img {
  width: 50px;
  height: 50px;
  transform: scale(1);  
  transition: transform 0.1s ease-out; 
}

.clicked {
  animation: scale-up-down 0.3s ease-in-out forwards;  
}

@keyframes scale-up-down {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);  
  }
}

@media (max-width: 1170px) { 
  .floating-toggle-button {
    top: 6px;
    right: 6px;
  }
  
  .floating-toggle-button img {
    width: 30px;
    height: 30px; 
  }
}


