/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

body { 
  min-height: 100vh;
  margin: 0;
  color: #2A5934;
  padding: 0;
  font-family: 'Roboto', sans-serif; 
}

.App { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
}

.main-content {
  flex: 1; /* Allows it to grow to fill available space */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Start content from the top */ 
  margin: 0px 120px 60px 120px;
  max-width: 100%; /* Allow content to shrink on smaller screens */
  border: 0.2em solid #000; /* Border thickness relative to ems */
  border-radius: 1em; /* Rounded corners relative to font size */
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.1); 
  overflow: hidden; 
  backdrop-filter: blur(8px);  
  -webkit-backdrop-filter: blur(8px);
}


.flex-map-container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 246px);     
  height: calc(100vh - 116px);
  overflow: hidden;
}

.map-container {
  height: 100%; 
  width: 100%; 
  overflow: hidden;
}

.last-updated-main { 
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 
               0 0 10px rgba(255, 255, 255, 0.3), 
               0 0 20px rgba(255, 255, 255, 0.2); 
}

.page-title {
  text-align: center;
  margin-bottom: 40px;
  color: #fff;  
}

.no-data {
  line-height: 1.6;
  color: #ff0000;  
}

/* Normal buttons styling */
.button-group {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px; 
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Space between buttons */
  z-index: 10;
}

/* Fixed menu bar styling for smaller screens */
.fixed-menu-bar {
  transform: translateY(25px);
  width: 60%;
  height: 50px;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 10px 10px;
  /* Adjust padding for a balanced layout */
  font-size: 14px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.2em; 
  box-sizing: border-box;
  /* Ensure padding and border are included in width */
  overflow: hidden;
  backdrop-filter: blur(16px);  
  -webkit-backdrop-filter: blur(16px);
}

.menu-bar-actions {
position: relative; /* Allow positioning of the active tab indicator */
width: 100%; /* Full width for evenly spaced tabs */
  display: flex;
justify-content: space-around; /* Even spacing between tabs */
  align-items: center; 
}

.menu-bar-actions div { 
flex: 1; /* Ensure equal width for all tabs */
text-align: center;
cursor: pointer;
padding: 10px 0;
color: white;
}

.menu-bar-actions div:hover {
color: lightgray; /* Hover effect for better UX */
}

/* Active tab indicator */
.menu-bar-actions::before {
content: "";
position: absolute; 
bottom: 0; /* Position at the bottom of the tabs */
left: 0;
height: 100%;  
width: calc(100% / 3); /* Adjust to match tab width */
background-color: rgba(255, 255, 255, 0.2); /* Block color (adjust as needed) */
border-radius: 10px;
transform: translateX(calc(var(--active-tab, 0) * 100%)); /* Move indicator to the active tab */
transition: transform 0.4s ease; /* Smooth movement on selection */
}

/* Active tab styling */
.menu-bar-actions div.active { 
font-weight: bold;
} 
/* .fixed-menu-bar {
  display: flex;
  position: fixed;
  position: absolute; 
  top: 0; 
  left: 50%;
  transform: translate(-50%, 25px); 
  width: 50%;
  height: 50px;  
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  padding: 10px 20px;
  font-size: 16px;  
  z-index: 1000;
  justify-content: space-between;
  align-items: center; 
  border-radius: 1.2em; 
  box-sizing: border-box;
  overflow: hidden;
  backdrop-filter: blur(16px);  
  -webkit-backdrop-filter: blur(16px);
} 

.menu-bar-actions {
  display: flex;
  align-items: center;
  gap: 15px;
} */


/* .view-toggle-button {
  background-color: #4caf50;
  color: white; 
  padding: 10px 15px;
  font-size: 16px; 
  border-radius: 5px;
  cursor: pointer;  
  border: none; 
} */

/* .view-toggle-button:hover {
  background-color: #45a049;
} */

.disclaimer-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px 30px; 
  background-color: hsla(0, 0%, 90%, 0.9); /* Light, glassy appearance */
  color: black;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500; /* Medium font weight for clear legibility */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth hover effects */
}

.disclaimer-button:hover {
  background-color: hsla(0, 0%, 80%, 1); /* Slightly darker hover state */
  transform: scale(1.02); /* Adds a subtle zoom effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25); /* Slightly deeper shadow */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content { 
  padding: 20px;
  border-radius: 10px; 
  text-align: center;
  position: relative; 
  width: 60%;
  background-color: hsla(0, 0%, 10%, 0.35);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px); /* Safari support */ 
  box-shadow: 0 0 0 0.125em hsla(var(--hue), 90%, 90%, 0) inset; 
}

.disclaimer-message-title {
  color: #ff5e5e
}

.disclaimer-message {
  text-align: left; 
  color: white;
 
  line-height: 1.4;
  color: hsla(0, 0%, 100%, 0.9);  
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);  
  font-weight: 300;  
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.close-button:hover {
  color: #000;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1170px) {
  .map-view .fixed-menu-bar {
    position: fixed; /* Fixes the menu bar at the top */
    top: 0; /* Ensures it's at the top */
    left: 15%; /* Adjust based on your layout */
    padding: 4px 4px;
    font-size: 12px;
    width: 70%;
    height: 40px; 
    line-height: 22px; 
} 
.fixed-menu-bar { 
  padding: 0px 4px;
  font-size: 12px;
  width: 70%;
  height: 40px; 
  line-height: 22px; 
} 

  .menu-bar-actions {
    display: flex;
    align-items: center;
    gap: 10px;  
    flex-wrap: wrap;  
  }
  .line-with-outline {
    stroke: black;
    stroke-width: 2px;
  } 

  .disclaimer-button { 
    bottom: 5px;
    right: 12px; 
  } 

  .last-updated-main {
    font-size: 10px; /* Smaller font for last-updated */ 
    margin-left: 10px;
    margin-right: auto; /* Push other elements away */
    white-space: nowrap; /* Prevent wrapping */  
    bottom: 10px;
    left: 0px; 
  }

  .map-view .main-content {
    margin: 0; /* Removes all margins */
    border: none;
  }
 
  .main-content {   
    margin: 0px 20px 50px 20px; 
  }

  .flex-map-container { 
    top: 40px; /* Height of the menu bar */
    left: 0;
    width: 100vw;
    height: calc(100vh - 10px); /* Subtracts the menu bar height to fit perfectly */
    z-index: 1; /* Keeps the map below the menu bar */
  }

  .hide {
    display: none;
  }

  .modal-content {
    width: 80%;
  } 

  body::before {
    backdrop-filter: blur(8px);  
    -webkit-backdrop-filter: blur(8px);
  }

  .menu-bar-actions div {  
    padding: 5px 0; 
    }
 
}
 
