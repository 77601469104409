/* General Page Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
} 

.simple-view-title-simple {
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.data-last-updated-simple {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.no-data-message-simple {
  font-size: 16px;
  color: #999;
}

/* Routes Container */
.routes-container-simple {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Individual Route Section */
.route-section-simple {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.route-name-simple {
  font-size: 22px;
  margin-bottom: 10px;
}

.section-title-simple {
  font-size: 18px;
  color: #007bff;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

/* Global Incidents */
.global-incidents-list-simple {
  list-style: none;
  padding: 0;
}

.global-incidents-list-simple .incident-item-simple {
  margin-bottom: 10px;
}

.global-incidents-list-simple .incident-item-simple p {
  margin: 5px 0;
}

/* Alerts Section */
.alerts-list-simple {
  list-style: none;
  padding: 0;
}

.alerts-list-simple .alert-item-simple {
  margin-bottom: 5px;
}

.no-alerts-message-simple {
  font-size: 14px;
  color: #888;
}

/* Subroutes Section */
.subroutes-section-simple {
  margin-top: 20px;
}

.subroute-simple {
  margin-bottom: 20px;
}

.subroute-direction-simple {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.travel-times-table-simple {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.travel-times-table-simple th,
.travel-times-table-simple td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.travel-times-table-simple th {
  background-color: #f2f2f2;
}

/* Incidents Section */
.incidents-section-simple {
  margin-top: 15px;
}

.incidents-list-simple {
  list-style: none;
  padding: 0;
}

.incidents-list-simple .incident-item-simple {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.incidents-list-simple .incident-item-simple p {
  margin: 5px 0;
}

.incident-description-simple {
  margin: 10px 0;
}

.incident-message-simple {
  font-style: italic;
  color: #555;
}

.incident-details-simple {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  color: #666;
}

/* Additional Impacts and Detours */
.additional-impacts-simple,
.detours-simple {
  margin-top: 10px;
}

.additional-impacts-simple ul,
.detours-simple ul {
  list-style: none;
  padding-left: 15px;
}

.additional-impacts-simple ul li,
.detours-simple ul li {
  margin-bottom: 5px;
}

/* Last Updated */
.last-updated-simple,
.last-updated-by-simple {
  font-size: 12px;
  color: #999;
}

.no-incidents-message-simple,
.no-subroutes-message-simple {
  font-size: 14px;
  color: #888;
}
