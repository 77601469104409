/* Styles for custom popups */

.leaflet-popup-content-wrapper {
    background-color: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    color: #fff;
}

.leaflet-popup-tip {
    background-color: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(12px);
}

.leaflet-container .leaflet-control-attribution {
    background-color: hsla(0, 0%, 20%, 1);
    color: #fff; 
}

.leaflet-container .leaflet-control-attribution a { 
    color: #50CDFF;
}

.incident-icon .severity-icon { 
    color: #ffb703; /* Golden Yellow */
    text-shadow: none; /* Optional: Remove shadow if it's not needed */
    -webkit-text-stroke: .7px #000; /* White outline for the glyph */
    -webkit-text-fill-color: #ffb703; /* Fills the glyph with the original color */
    font-size: 24px; /* Adjust as needed */
}


.incident-icon.minor .severity-icon {
    color: #ffb703; /* Golden Yellow */ 
}

.incident-icon.major .severity-icon {
    color: #ff7c43; /* Bright Orange */  
} 

.incident-icon.severe .severity-icon {
    color: #d90429; /* Deep Red */ 
} 
