.route-container { 
  margin-bottom: 30px;
  background-color: hsla(0, 0%, 10%, 0.35);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px); /* Safari support */
  padding-bottom: 5px;
  border-radius: 3.75em;
  box-shadow: 0 0 0 0.125em hsla(var(--hue), 90%, 90%, 0) inset; 
  width: 60%;
  overflow: hidden;
}
.route-top:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3.75em;
  transition: border-radius 0.8s cubic-bezier(0.33, 1, 0.67, 1);
}

.route-container.expanded .route-top:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3.75em 3.75em 0 0;
} 

.route-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  background: none;  
  border-radius: 8px 8px 0 0; 
}

.route-name {
  text-align: left;
  font-size: 2.2rem;
  font-weight: bold;
  color: #fff;
  min-width: 25%; 
}

.route-restrictions {
  flex: 1;  
  display: flex; /* Use flexbox to manage its content */
  flex-direction: column; /* Stack items vertically if needed */
  align-items: flex-start; /* Center content horizontally */
  text-align: left;
  justify-content: flex-start;  
  position: relative;
}

.route-restrictions-header {
   margin: 0; 
   display: block;  
   color: #fff;  
   padding: 0;
}

.alert-message {
  color: #ffd148; 
  font-weight: bold;
  text-align: left;  
} 

.road-alerts { 
  margin-top: 10px; /* Add spacing between the header and alerts */
  width: 100%; /* Ensure it spans the full width */ 
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack alerts vertically */
  align-items: flex-start; /* Align each alert to the left */
}

.no-restrictions {
  margin-top: 10px;
  color: #80da3c;
  font-weight: bold; 
}

.route-content {
  padding: 15px;
  opacity: 0; /* Start hidden */
  transform: translateY(-20px); /* Slide in from above */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.route-container.expanded .route-content {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Slide into position */
}

/* Styles for incident-cards-container */
.incident-cards-container:empty {
  display: none;
}

.incident-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  opacity: 0; /* Start hidden */
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition-delay: 0.15s; /* Slight delay for staggered effect */
}

.route-container.expanded .incident-cards-container {
  opacity: 1;
  transform: translateY(0);
}

/* Styles for subroutes-container */
.subroutes-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  opacity: 0; /* Start hidden */
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition-delay: 0.15s; /* Slight delay for staggered effect */
}

.route-container.expanded .subroutes-container {
  opacity: 1;
  transform: translateY(0);
}

/* Keyframes for fallback animations */
@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeSlideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
} 
.incident-cards-container.hidden,
.subroutes-container.hidden {
  animation: fadeSlideOut 0.3s ease forwards;
}

@media (max-width: 1024px) {
  .route-name { 
    font-size: 1.6rem; 
  }
  .route-container { 
    width: 85%; 
  }

  .route-restrictions-header {  
 }

 .alert-message {
  font-size: .9rem; 
 }
 
}