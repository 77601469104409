.subroute-accordion {   
}

.subroute-direction {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  margin-top: 0;
}

.incident-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 15px;
}

.no-incidents-reported {
  font-style: italic;
  color: #d1d1d1;
  margin-top: 15px;
}

.destinations-table {
  width: 100%; /* Full width of the parent */
  border-collapse: collapse; /* No gaps between cells */
  color: #f0f0f0; /* Light text color */
  font-family: Arial, sans-serif;
}

.destinations-table thead th {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly darker header background */
  color: #ffffff; /* Bright white for header text */
  font-weight: 600;
  padding: 12px;
  text-align: left;
}

.destinations-table tbody tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); /* Subtle border between rows */
}

.destinations-table tbody td {
  padding: 10px;
  color: #e0e0e0; /* Slightly lighter text color for body rows */
}

.destinations-table tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05); /* Light background for even rows */
}

.destinations-table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.15); /* Highlight row on hover */
  transition: background-color 0.2s ease;
}
