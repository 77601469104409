/* src/components/IncidentCard.css */

.incident-card {
  background-color: rgba(255, 255, 255, 0.42); /* Light semi-transparent background */
  backdrop-filter: blur(8px); /* Subtle blur effect */
  border-radius: 3.25em;
  padding: 20px;
  margin: 0px 5px 5px 5px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Softer shadow for depth */
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  width: 100%; 
}
 
/* Header & Icon Styling */
.incident-card h4 { 
  margin-top: 0;
  margin-left: 25px;
  display: flex;
  align-items: center;  
  font-weight: bolder;
} 
 
.incident-card p {
  margin: 8px 0;
  color: #fff;  
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15); /* Subtle shadow for readability */
  font-weight: 450; 
}

.incident-card p strong {
  color: #0099ff; /* Accent blue for the text */
  text-shadow: 
    -1px -1px 0 #fff, /* Top left */
    1px -1px 0 #fff, /* Top right */
    -1px 1px 0 #fff, /* Bottom left */
    1px 1px 0 #fff; /* Bottom right */
}


/* Timestamp Styling */
.last-updated {
  font-size: 0.85em;
  color: #e2e2e2 !important; /* Neutral gray */
  margin-top: 10px;
  font-style: italic;
}

/* List Styling */
.incident-card ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 8px 0;
}

.incident-card ul li {
  margin: 4px 0;
  color: #fff;
}

.incident-card p,
.incident-card ul,
.incident-card li,
.last-updated {
  margin-left: 10px;
}

.incident-card .severity-icon {
  margin-right: 10px;
  font-size: 1.4em;
  vertical-align: middle;
  color: #ffb703; /* Default icon color */
}

/* Severity Colors */
.incident-card.minor .severity-icon {
  color: #ffb703; /* Golden Yellow */
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3); 
}
 
.incident-card.major .severity-icon {
  color: #ff7c43; /* Bright Orange */ 
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
} 

.incident-card.severe .severity-icon {
  color: #d90429; /* Deep Red */
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
} 

.severity-text {
  color: #ff3b3b;
}

/* .severity-text.minor {
  color: #ffb703;
}
.severity-text.major {
  color: #ff7c43;
}
.severity-text.severe {
  color: #d90429;
} */

/* Responsive layout */
@media (max-width: 800px) {
  .incident-card {
    flex: 1 1 calc(50% - 30px);
  }
}

@media (max-width: 500px) {
  .incident-card {
    flex: 1 1 100%;
  }
}
